var currency = require("currency-formatter");
const prettyBytes = require('pretty-bytes');
export const money = (value, decimalSeparator) => {
  return currency.format(value, {
    code: "USD",
    decimalDigits: 0,
    precision: 0,
    decimalSeparator: "."
    //typeof decimalSeparator !== "undefined" ? decimalSeparator : ","
  });
};
export const downloadLink = (download_url, file_name) => {
  var anchor = document.createElement('a');
  anchor.href = download_url;
  anchor.target = '_blank';
  anchor.download = file_name;
  anchor.click();
}
export const Bytes = value => prettyBytes(value);
