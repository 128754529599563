import React, { Component } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Title } from "./";

export default class EditView extends Component {
  state = {
    image: null,
    expanded: false,
  };

  render() {
    const { image } = this.state;

    return (
      <Edit title={<Title />} {...this.props}>
        <SimpleForm>
          <Grid container fullWidth spacing={16}>
            <Grid item xs={6}>
              <TextInput fullWidth source="name" label="Nombre" />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                label="Padre"
                source="parent_id"
                reference="categories"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}
