import React from "react";
import CategoryList from "./CategoryList";
import CategoryEdit from "./CategoryEdit";
import CategoryCreate from "./CategoryCreate";
import CategoryShow from "./CategoryShow";

const Title = ({ record }) => {
  return <span>{record ? `Editar ${record.name}` : "Listar usuarios"}</span>;
};

const ImgField = ({ record }) => {
  return (
    <img
      src={`https://fithubapp.s3.amazonaws.com/${record.path_image}`}
      height="40px"
      width="40px"
    />
  );
};

export {
  CategoryList,
  CategoryEdit,
  Title,
  CategoryCreate,
  CategoryShow,
  ImgField
};
